import React from "react";
import "./Footer.css";
import {
  FaMapMarkerAlt,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { BsTelephoneFill } from "react-icons/bs";
import logo from "../assests/Logo.png";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate("");
  return (
    <div className="containerpies">
      <div className="softFooter">
        <div className="footerBody">
          <img src={logo} className="widthmobifoot" />
          <span className="footTitltlSoft">Projectsoft</span>
          <span></span>
        </div>
        <hr className="resMenu_web" />
        <div className="RowFootsoft">
          <div className="resMenu_web">
            <div className="cOlsoft">
              <span className="FirstcOlsoftheaD">Quick Links</span>
              <span
                className="FirstcOlsoftsuB"
                onClick={() => {
                  navigate("/");
                }}
              >
                Home
              </span>
              <span
                className="FirstcOlsoftsuB"
                onClick={() => {
                  navigate("/about");
                  // window.scrollTo(0, 0);
                }}
              >
                About Us
              </span>
              <span
                className="FirstcOlsoftsuB"
                onClick={() => {
                  navigate("/contact");
                }}
              >
                Contact Us
              </span>
            </div>
          </div>

          <div className="cOlsoft">
            <span className="FirstcOlsoftheaD">Products</span>
            <span
              className="FirstcOlsoftsuB"
              onClick={() => {
                navigate("/kams");
              }}
            >
              {" "}
              KAMS (Consruction Activity Management system)
            </span>
            <span
              className="FirstcOlsoftsuB"
              onClick={() => {
                navigate("/rems");
              }}
            >
              {" "}
              REMSS (Real Estate Marketing & Sales Software )
            </span>
            <span className="FirstcOlsoftsuB">
              IPAMS (Intellectual Property Activity Management Software)
            </span>
            <span className="FirstcOlsoftsuB">
              {" "}
              DDMS (Digital Drawing Management Software )
            </span>
            <span className="FirstcOlsoftsuB">
              DBCCM (Digital Book Content Creation Management Solution )
            </span>
            <span className="FirstcOlsoftsuB">
              PAMS (Project Activities Management Solution )
            </span>
            <span className="FirstcOlsoftsuB">
              SDMS (Software Development Management Solution )
            </span>
          </div>

          <div className="cOlsoft">
            <span className="FirstcOlsoftheaD">Contact Us</span>
            <div className="d-flex flex-row gap-2">
              <span>
                <FaMapMarkerAlt />{" "}
              </span>
              <span className="FirstcOlsoftsuB">
                1st Floor, "CHETTINAD CHAMBERS", No 39, 5th Street <br />
                Radha Krishnan Salai, Mylapore, Chennai-
                <br />
                600004, Tamil Nadu, India.
              </span>
            </div>
            <div className="d-flex flex-row gap-2 align-items-center">
              <span>
                <GrMail />{" "}
              </span>
              <span className="FirstcOlsoftsuBa">support@projectsoft.com</span>
            </div>
            <div className="d-flex flex-row gap-2 align-items-center">
              <span>
                <BsTelephoneFill />{" "}
              </span>
              <span className="FirstcOlsoftsuBa">+91 98848 00819</span>
            </div>
          </div>
        </div>

        <div className="resMenu">
          <div className="d-flex flex-row gap-3 w-100 justify-content-between px-5 mt-3">
            <span
              className="FirstcOlsoftsuBm"
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </span>
            <span
              className="FirstcOlsoftsuBm"
              onClick={() => {
                navigate("/about");
                // window.scrollTo(0, 0);
              }}
            >
              About Us
            </span>
            <span
              className="FirstcOlsoftsuBm"
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact Us
            </span>
          </div>
        </div>

        <hr />

        <div className="footfoOT">
          <div className="footfoOTteXt mb-4">
            Copyright &#169; 2023 Project Soft
          </div>
          <div className="d-flex gap-4 mb-4">
            <FaFacebookF style={{ cursor: "pointer" }} />
            <FaTwitter style={{ cursor: "pointer" }} />
            <FaLinkedinIn style={{ cursor: "pointer" }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
