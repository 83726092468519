import React from "react";
import "./About.css";
import mdLogo from "../assests/mdlogo.png";

function About() {
  return (
    <div>
      <div className="aboutbanner">
        <div className="aboutBannerBlur">
          <div className="abouTilr">About Us</div>
        </div>
      </div>

      {/* about us start */}

      <div className="container">
        <div className="aboyuiohead mt-3">About Us</div>
        <div className="aboyuioheadMian">
          Project Soft Global Private Limited is promoted by Pioneer Group of
          Companies.
        </div>
        <div className="aboyuioheadMianSecf mt-3">
          Chennai-based Pioneer Group was established in the year 1961.
          Initially, it was launched in the name of PIONEER ENGINEERING
          CORPORATION (PEC) as a Proprietary firm under the leadership of the
          Late Prof. K. Subbiah, who was a Post Graduate of Structural
          Engineering from the University of Manchester, UK. He was the acting
          Principal of PSG College of Technology – Coimbatore, before initiating
          PEC.
        </div>
        <div className="aboyuioheadMianSecf mt-3">
          The firm initially provided consultancy services and has designed
          several Textile Spinning Mills, Sugar Mills, Power Plants, etc. Later
          the firm diversified its portfolio under the banner of “PIONEER
          GROUP”.
        </div>
        <div className="aboyuioheadMianSecf mt-3">
          Later, the Group ventured into new areas of business like a large
          foundry making automobile castings, a textile spinning mill, a large
          shrimp farm, construction of residential commercial and IT buildings,
          Villas, a shopping mall, and Software Development.
        </div>
        <div className="aboyuioheadMianSecf mt-3">
          The firm built a team of talented and dedicated engineers to build
          Thermal Power Plants, Refineries, a Fertilizer Plant, an Aluminium
          plant, Aqua Firms, Shipyard, Sugar Factory, Steel Plants, Textile
          Mills, Naval Base, Workshop Building, and some Defence establishments
          and earned recognition as a reliable, reputed building and
          infrastructure contractors.
        </div>
      </div>
      {/* about us end */}

      {/* Leadership start */}
      <div className="container mt-3">
        <div className="aboLeadertitle">Leadership</div>
        <div className=" Column_chanage">
          <div className="d-flex flex-column gap-2 widhtLeadrtiou">

          <div className="mdlogocss resMenu">
            <img src={mdLogo} className="Photofix" />
          </div>
            <div className="leadercontentaboU">
              Mr. Sarath Kakumanu S/o. Late Prof. K. Subbiah took the reins of
              the Group after Mr. Subbiah.
            </div>
            <div className="leadercontentaboU">
              Mr. Sarath Kakumanu pursued his Bachelor in Architecture from the
              School of Architecture and Planning, University of Madras now
              known as Anna University. Sarath Kakumanu did his Master’s in
              Architecture from Kent State University of Ohio in 1976-78 and
              secured an M.S. in Urban Planning at Akron University, Ohio, in
              1979.
            </div>
            <div className="leadercontentaboU">
              Software Development started in 1992. The software journey started
              with end-to-end Konstruction Activity Management Software(KAMS).
            </div>
          </div>
          <div className="mdlogocss resMenu_web">
            <img src={mdLogo} />
          </div>
        </div>

        <div className="leadercontentaboU mt-3">
          Project Soft has built multiple other software like
        </div>
        <div className="leadercontentaboU mt-3">
          <li> KAMS (Konstruction Activity Management Solution) </li>
          <br />
          <li> REMSS (Real Estate Marketing & Sales Solution) </li> <br />
          <li>
            {" "}
            IPAMS (Intellectual Property Activity Management Solution){" "}
          </li>{" "}
          <br />
          <li> DDMS (Digital Drawing Management Solution) </li> <br />
          <li>
            {" "}
            DBCCMS (Digital Book Content Creation Management Solution){" "}
          </li>{" "}
          <br />
          <li> PAMS (Project Activities Management Solution) </li> <br />
          <li> SDMS (Software Development Management Solution)</li>
        </div>

        <div className="leadercontentaboU mt-3 widhtLeadrtiou">
          Pioneer, under another Group company called ‘KPOST Software Private
          Limited has come up with the most advanced communication systems like
          KPOST open communication platforms for personal use and also KSMACC
          for CLOSED communication for Individuals/Private Groups, Businesses,
          Institutions, and Governments.
        </div>

        <div className="leadercontentaboU mt-3 widhtLeadrtiou">
          Pioneer under another Group company called ‘UEducate Global Private
          Limited’ has come up with an educational ecosystem called ‘UEDUCATE’
          with a vision of providing education that is equitable, accessible,
          and affordable to all students.
        </div>

        <div className="leadercontentaboU mt-3 mb-5 widhtLeadrtiou">
          UEducate consists of
          <li>KAMPUS, the Educational Institution Management System,</li>
          <li>KATBook, Collaborative Advanced Digital Text Book,</li>
          <li>KSMACC, The CLOSED Communication for the institution.</li>
          <li>
            KATBook Store, The complete E-commerce site for KAT Books with
            Content Marketplace.
          </li>
          <li>
            UStudy, the Global Academic Aggregator for Higher Education and
            Skill Development.
          </li>
        </div>
      </div>
      {/* Leadership end */}
    </div>
  );
}

export default About;
