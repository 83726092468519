import React from "react";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import bussiness from "../assests/bussiness.png";
import enginee from "../assests/enginee.png";
import building from "../assests/build.png";
import ppoint from "../assests/point.png";
import Construction from "../assests/Construction.png";
import monitor from "../assests/monitor.png";
import service from "../assests/service.png";
import Purchase from "../assests/Purchase.png";
import human from "../assests/human.png";
import finance from "../assests/finance.png";
import Workflow from "../assests/Workflow.png";
import inbuilt from "../assests/inbuilt.png";

function Kams() {
  return (
    <div>
      <div className="kamsBaneerPic">
        <div className="container">
          <div className="d-flex flex-row justify-content-start gap-5">
            <div className="d-flex flex-column kamSPADD">
              <span className="kamsTitlE">KAMS</span>
              <span className="kamsTitlE2">
                Construction Activity Management Solution
              </span>
              <span className="kamsTitlE3">
                Cost estimation in fingertips with detailed libraries.
              </span>
            </div>
            <div className="resMenu_web">
              <div className="d-flex flex-column kamsFeua gap-3">
                <div className="feauKAMs">Features</div>
                <div className="feauKAMsconTEnt">
                  <IoIosArrowDroprightCircle /> &nbsp; Master Data Management
                </div>
                <div className="feauKAMsconTEnt">
                  <IoIosArrowDroprightCircle /> &nbsp; Drawing Management
                </div>
                <div className="feauKAMsconTEnt">
                  <IoIosArrowDroprightCircle /> &nbsp; Building Information
                  Modelling
                </div>
                <div className="feauKAMsconTEnt">
                  <IoIosArrowDroprightCircle /> &nbsp; Point of Work – Micro-level
                  approach
                </div>
                <div className="feauKAMsconTEnt">
                  <IoIosArrowDroprightCircle /> &nbsp; Construction Scheduling &
                  Monitoring
                </div>
                <div className="feauKAMsconTEnt">
                  <IoIosArrowDroprightCircle /> &nbsp; Project Appraisal
                </div>
                <div className="feauKAMsconTEnt">
                  <IoIosArrowDroprightCircle /> &nbsp; Service and Contract
                  Management
                </div>
              </div>
            </div>
            <div className="resMenu_web">
              <div className="d-flex flex-column kamsFeua2 gap-3">
                <div className="feauKAMsconTEnt">
                  {" "}
                  <IoIosArrowDroprightCircle /> &nbsp; Purchase Management
                </div>
                <div className="feauKAMsconTEnt">
                  {" "}
                  <IoIosArrowDroprightCircle /> &nbsp; Human Resource Management
                </div>
                <div className="feauKAMsconTEnt">
                  {" "}
                  <IoIosArrowDroprightCircle /> &nbsp; Finance Management
                </div>
                <div className="feauKAMsconTEnt">
                  {" "}
                  <IoIosArrowDroprightCircle /> &nbsp; Monitoring with Reports
                </div>
                <div className="feauKAMsconTEnt">
                  {" "}
                  <IoIosArrowDroprightCircle /> &nbsp; Workflow management
                </div>
                <div className="feauKAMsconTEnt">
                  {" "}
                  <IoIosArrowDroprightCircle /> &nbsp; KSMACC – Inbuilt Advanced
                  CLOSED Communication
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="resMenu_web">
        <div className="container">
          <div className="kafeauTEr">KAMS Features</div>
          <div className="kafeauTEr2">
            KAMS is an end-to-end digital construction activity management
            solution for any type of construction from a small
          </div>
          <div className="kafeauTEr2">
            residential building to a huge infra project.
          </div>
        </div>
      </div>

      <div className="resMenu">
        <div className="kafeauTEr">KAMS Features</div>
        <div className="kafeauTEr2">
          KAMS is an end-to-end digital construction activity management
          solution for any type of construction from a small
          residential building to a huge infra project.
        </div>
      </div>

      <div className="resMenu_web">
        <div className="container">
          <div className="masatamana">
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-column">
                <div className="masterkAms_title">Master Data Management</div>
                <div className="masterkAms_content">
                  1. Accommodates thousands of standard industry data.
                  <br />
                  2. Associated Technical and Cost data are provided for each
                  datum, if applicable.
                  <br />
                  3. Enables the user to create their own breakdown of
                  construction activities, with the help of the dynamic attributes
                  and variables creation facility for the construction work
                  activities.
                  <br />
                  4. Enables users to create their own IOCA – Item of Construction
                  Activity.
                  <br />
                  5. Each IOCA will have a Unique code for identification.
                  <br />
                  6. Constant data provided for Material, Labour, Machinery, and
                  Consumables in their respective Masters.
                  <br />
                  7. Rates, and Taxes for material, labor, equipment, and
                  consumables are configurable.
                  <br />
                  8. Cost estimation of each and every item of construction work
                  activity is made easy with a cost data library available for all
                  users.
                  <br />
                  9. Enables the users to generate various estimates for specific
                  location requirements in the project.
                </div>
              </div>

              <div className="widthmasterImageandContent">
                <img src={bussiness} />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="masatamana2">
            <div className="d-flex flex-row justify-content-start">
              <div className="widthmasterImageandContent2">
                <img src={enginee} />
              </div>

              <div className="d-flex flex-column">
                <div className="masterkAms_title2">Drawing Management</div>
                <div className="masterkAms_content2">
                  1. Digital management of Drawings including storage and
                  tracking.
                  <br />
                  2. Manages information on all the drawings of a Construction
                  Project including
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Architectural drawings, Land Scraping
                  Drawings.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Structural drawings, Infrastructure
                  drawings
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;MEP drawings, Roads, Sewerage, Water
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Interior drawings including Civil,
                  Electrical, & Telecommunication
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Conceptual drawings
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Schematic drawings
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Approval drawings
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Construction Working drawings & Shop
                  drawings
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Preparatory drawings for Sales.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;CADD, BIM, and REVIT-powered sales
                  documentation drawings.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Location drawings.
                  <br />
                  3. Secure Storage, Maintenance, and Management
                  <br />
                  4. Powerful Search functionality for identification and
                  retrieval
                  <br />
                  5. Enables handling multiple file formats including DWG, BMP,
                  JPG, PDF, etc.
                  <br />
                  6. Access is secured.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="masatamana3">
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-column">
                <div className="masterkAms_title">
                  Building Information Modelling
                </div>
                <div className="masterkAms_content">
                  1. Quantity take-off can be precisely imported from BIM
                  (Building Information Modelling)
                  <br />
                  2. Reduces quantity take-off time and thus saves estimator’s
                  time.
                  <br />
                  3. Export from Excel if BIM is not used by the User.
                  <br />
                  4. Manual Quantity Take-off.
                </div>
              </div>

              <div className="widthmasterImageandContent3">
                <img src={building} />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="masatamana4">
            <div className="d-flex flex-row justify-content-start">
              <div className="widthmasterImageandContent4">
                <img src={ppoint} />
              </div>

              <div className="d-flex flex-column">
                <div className="masterkAms_title2">
                  Point of Work (POW)– Micro-level approach
                </div>
                <div className="masterkAms_content2">
                  1. Automatic planning and estimation of each and every POW at
                  any specific location of the construction project. World’s first
                  to have this feature.
                  <br />
                  2. Addresses any work to POW in any location, to any IOCA.
                  <br />
                  3. Enables the user to plan the respective construction
                  activities to the identified point of work to the execution and
                  monitoring staff, and also the sub-contractor and his workers.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="masatamana5">
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-column">
                <div className="masterkAms_title">Construction Project Setup</div>
                <div className="masterkAms_content">
                  1. Creation of IOCA (Item of Construction Activity)
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;IOCWA (Item of Construction Work
                  Activity) and IOCMA (Item of Construction Management Activity) –
                  Separate for Work and Management activities.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Unique method of IOCA creation, under
                  a type of work.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Creation of any number of IOCA, based
                  on the attribute and variables.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Enabling the creation of precise
                  IOCA.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Unique model to create BOQ for any
                  IOCA on its location
                  <br />
                  2. The copy Bill of Quantities (BOQ) feature allows the
                  utilization of data from one item of construction work activity
                  to multiple items of construction work activity, as well as from
                  one location to multiple locations within a project.
                  <br /> 3. Logical breakdown of work location for various types
                  of structure/infrastructure as per the requirement of the
                  project
                  <br />
                  4. The type of Work location tier attributes can be further
                  broken down, as per requirements of the project.
                  <br />
                  5. Categorization of Construction Work Activity into Civil,
                  Mechanical etc. is possible.
                  <br />
                  6. The BOQ for all IOCA is taken based on the final location of
                  the IOCA under each base location.
                  <br />
                  7. Micro-level planning up to the Point of Work can be done
                  automatically / Manually.
                  <br />
                  8. Auto generation of Input Requirements based on Schedules for
                  any type of construction.
                  <br />
                  9. Inbuilt quality control to monitor and control the Quality.
                  <br />
                  10. Auto generation of Bills on the approved POW by the Project
                  Manager on completion.
                  <br />
                  11. Enables periodic work allocation to execution staff and
                  contractors based on the IOCA & location
                  <br />
                  12. View of the current stage of work allocated is enabled.
                  <br />
                  13. Efficient and effective way of reporting defective work at
                  any POW by posting an image/photo to the Project Manager.
                  <br />
                  14. Multiple periodic Reports for quick project appraisal like
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Planned vs. actuals, and
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Construction work status Reports.
                </div>
              </div>

              <div className="widthmasterImageandContent5">
                <img src={Construction} />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="masatamana6">
            <div className="d-flex flex-row justify-content-start">
              <div className="widthmasterImageandContent6">
                <img src={monitor} />
              </div>

              <div className="d-flex flex-column">
                <div className="masterkAms_title2">Monitoring with Reports</div>
                <div className="masterkAms_content2">
                  Various MIS Reports of Construction can be generated for the
                  Elements cited below,
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Category, Selected Category.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Sub-Category, Selected Sub-Category.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Type of Work, Selected Type of Work.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Location, Selected Location.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Item of Construction Activity (IOCA),
                  Selected IOCA.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Point of Work (POW), Selected POW.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Total Work/Completed Work/Pending
                  Work.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Detailed Format/Consolidated Format.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="masatamana7">
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-column">
                <div className="masterkAms_title">
                  Service and Contract Management
                </div>
                <div className="masterkAms_content">
                  1. Quick creation of RFQ (Request for Quote) / Tender with
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Predefined templates
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Abstract of BOQ
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; The estimated cost of work is based
                  on the rates available with the company.
                  <br />
                  2. The system enables quick
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Release of Work Order
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Approval of the Work
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Reconciliation of Work Order
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Appraisal of the contractor
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Raising of Bills
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; RA Bills
                  <br />
                  3. Electronic process for Floating and receiving and Bidding (to
                  confirm).
                  <br />
                  4. Auto and immediate evaluation of RFQ / Tender.
                  <br />
                  5. Auto sensitive Analysis as per the requirement.
                  <br />
                  6. Library of standard templates of LOI, Work Order, and Tender
                  that can be used for any type of construction work.
                  <br />
                </div>
              </div>

              <div className="widthmasterImageandContent7">
                <img src={service} />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="masatamana8">
            <div className="d-flex flex-row justify-content-start">
              <div className="widthmasterImageandContent8">
                <img src={Purchase} />
              </div>

              <div className="d-flex flex-column">
                <div className="masterkAms_title2">Purchase Management</div>
                <div className="masterkAms_content2">
                  1. The system enables
                  <br />
                  i. Raising the indent for materials from
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; The final BOQs
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Planning up to the POW
                  <br />
                  ii. Request for proposal
                  <br />
                  iii. Validation of received proposals
                  <br />
                  iv. Release of Purchase Orders
                  <br />
                  v. Creation of Rate Contractors
                  <br />
                  vi. Machine hire orders
                  <br />
                  vii. Appraisal to vendors
                  <br />
                  viii. Reconciling purchase and hire orders
                  <br />
                  ix. Register of Released POs and HOs
                  <br />
                  2. Alerts and escalations to speed up the procurement process
                  till the material is delivered.
                  <br />
                  3. Efficient stock and cost management with rapid analysis of
                  stocks and deployment of them between the sites.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="masatamana9">
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-column">
                <div className="masterkAms_title">Human Resource Management</div>
                <div className="masterkAms_content">
                  1. Efficient, accurate human resource management
                  <br />
                  2. Payroll
                  <br />
                  3. Eliminates physical employee-wise files
                  <br />
                  4. Creation of Job profiles and Roles of the employees
                  <br />
                  5. All key data of the employees like PAN, Address, Insurance,
                  etc. is managed.
                  <br />
                  6. Integrated Payroll with e-payslip being sent to Katchup of
                  the employees.
                  <br />
                </div>
              </div>

              <div className="widthmasterImageandContent9">
                <img src={human} />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="masatamana10">
            <div className="d-flex flex-row justify-content-start">
              <div className="widthmasterImageandContent10">
                <img src={finance} />
              </div>

              <div className="d-flex flex-column">
                <div className="masterkAms_title2">Finance Management</div>
                <div className="masterkAms_content2">
                  1. Finance – can be integrated into Tally, SAP, and other
                  Accounting Software.
                  <br />
                  2. Maintain Sales Stock: Sold, Available, Blocked, On Hold,
                  un-Released
                  <br />
                  3. Customer Profile: CRM, Customer interaction Management, Sold
                  Units Management.
                  <br />
                  4. Booking form, creation of payment schedule, receipts against
                  schedule.
                  <br />
                  5. Receipt creation, Interest on Delayed payments, Excess
                  Amounts.
                  <br />
                  6. Advance Interest Calculations with the ability for interest
                  adjustments.
                  <br />
                  7. Auto Demand letter generation, Payment Reminders.
                  <br />
                  8. Collection reminders and collection aging lists
                  <br />
                  9. Market planning, Budgets/Forecasting.
                  <br />
                  10. Brokerage and Agent Management
                  <br />
                  11. Email Templates: Birthday Wishes, Greetings, Payments,
                  general bulk email to customers, SMS messages, etc.
                  <br />
                  12. Prospecting/pre-sales to sales closure lifecycle of sales
                  mapping.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="masatamana11">
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-column">
                <div className="masterkAms_title">Workflow management</div>
                <div className="masterkAms_content">
                  1. KAMS provides user access control and multi-level and
                  value-based approvals.
                  <br /> 2. Defective work management controls the quality of the
                  work.
                  <br />
                  3. Alerts to User on Non-Completion of work.
                  <br />
                  4. Approval History & Auditing.
                  <br />
                  5. Reminders and user-defined dashboards allow users to
                  experience smooth workflow management.
                  <br />
                  6. KAMS workflow management reduces complexity and controls the
                  project through automation.
                  <br />
                  7. Streamline the project from Project Initiation to Completion.
                  <br />
                  8. Construction of workflow based on the user’s requirements and
                  organization setup.
                  <br />
                </div>
              </div>

              <div className="widthmasterImageandContent11">
                <img src={Workflow} />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="masatamana12">
            <div className="d-flex flex-row justify-content-start">
              <div className="widthmasterImageandContent12">
                <img src={inbuilt} />
              </div>

              <div className="d-flex flex-column">
                <div className="masterkAms_title2">
                  KSMACC – Inbuilt Advanced CLOSED Communication
                </div>
                <div className="masterkAms_content2">
                  &nbsp;&nbsp; &#8226; &nbsp; End-to-End Encryption.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Role-based Communication Channels.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Continuity of the Communication.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Company Contact’s Directory.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Data owned and managed by the
                  Company.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Configurable as per needs. <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Administered by the Admin of the
                  company.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Patented features in Katchup and
                  Kall modules and much more advanced than WhatsApp / Telegram.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="resMenu">
        <div className="container">

          <div className="masatamana">
            <div className="d-flex flex-column justify-content-between">
              <div className="d-flex flex-column">
                <div className="masterkAms_title">Master Data Management</div>
                <div className="widthmasterImageandContent">
                  <img src={bussiness} width={210} height={200} />
                </div>
                <div className="masterkAms_content">
                  1. Accommodates thousands of standard industry data.
                  <br />
                  2. Associated Technical and Cost data are provided for each
                  datum, if applicable.
                  <br />
                  3. Enables the user to create their own breakdown of
                  construction activities, with the help of the dynamic attributes
                  and variables creation facility for the construction work
                  activities.
                  <br />
                  4. Enables users to create their own IOCA – Item of Construction
                  Activity.
                  <br />
                  5. Each IOCA will have a Unique code for identification.
                  <br />
                  6. Constant data provided for Material, Labour, Machinery, and
                  Consumables in their respective Masters.
                  <br />
                  7. Rates, and Taxes for material, labor, equipment, and
                  consumables are configurable.
                  <br />
                  8. Cost estimation of each and every item of construction work
                  activity is made easy with a cost data library available for all
                  users.
                  <br />
                  9. Enables the users to generate various estimates for specific
                  location requirements in the project.
                </div>
              </div>


            </div>
          </div>
        </div>

          <div className="container">
            <div className="masatamana2">
              <div className="d-flex flex-coluumn ">

                <div className="d-flex flex-column">
                  <div className="masterkAms_title2">Drawing Management</div>
                  <div className="widthmasterImageandContent2">
                    <img src={enginee} width={210} height={200} />
                  </div>

                  <div className="masterkAms_content2">
                    1. Digital management of Drawings including storage and
                    tracking.
                    <br />
                    2. Manages information on all the drawings of a Construction
                    Project including
                    <br />
                    &nbsp;&nbsp; &#8226; &nbsp;Architectural drawings, Land Scraping
                    Drawings.
                    <br />
                    &nbsp;&nbsp; &#8226; &nbsp;Structural drawings, Infrastructure
                    drawings
                    <br />
                    &nbsp;&nbsp; &#8226; &nbsp;MEP drawings, Roads, Sewerage, Water
                    <br />
                    &nbsp;&nbsp; &#8226; &nbsp;Interior drawings including Civil,
                    Electrical, & Telecommunication
                    <br />
                    &nbsp;&nbsp; &#8226; &nbsp;Conceptual drawings
                    <br />
                    &nbsp;&nbsp; &#8226; &nbsp;Schematic drawings
                    <br />
                    &nbsp;&nbsp; &#8226; &nbsp;Approval drawings
                    <br />
                    &nbsp;&nbsp; &#8226; &nbsp;Construction Working drawings & Shop
                    drawings
                    <br />
                    &nbsp;&nbsp; &#8226; &nbsp;Preparatory drawings for Sales.
                    <br />
                    &nbsp;&nbsp; &#8226; &nbsp;CADD, BIM, and REVIT-powered sales
                    documentation drawings.
                    <br />
                    &nbsp;&nbsp; &#8226; &nbsp;Location drawings.
                    <br />
                    3. Secure Storage, Maintenance, and Management
                    <br />
                    4. Powerful Search functionality for identification and
                    retrieval
                    <br />
                    5. Enables handling multiple file formats including DWG, BMP,
                    JPG, PDF, etc.
                    <br />
                    6. Access is secured.
                    <br />
                  </div>
              </div>
            </div>
          </div>

        </div>

          <div className="container">
            <div className="masatamana3">
              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-column">
                  <div className="masterkAms_title">
                    Building Information Modelling
                  </div>
                  <div className="widthmasterImageandContent3">
                  <img src={building} width={210} height={200} />
                </div>
                  <div className="masterkAms_content">
                    1. Quantity take-off can be precisely imported from BIM
                    (Building Information Modelling)
                    <br />
                    2. Reduces quantity take-off time and thus saves estimator’s
                    time.
                    <br />
                    3. Export from Excel if BIM is not used by the User.
                    <br />
                    4. Manual Quantity Take-off.
                  </div>
                </div>

                
              </div>
          </div>
        </div>

        <div className="container">
          <div className="masatamana4">
            <div className="d-flex flex-column justify-content-start">
              <div className="d-flex flex-column">
                <div className="masterkAms_title2">
                  Point of Work (POW)– Micro-level approach
                </div>
                <div className="widthmasterImageandContent4">
                <img src={ppoint}  width={210} height={200} />
              </div>
                <div className="masterkAms_content2">
                  1. Automatic planning and estimation of each and every POW at
                  any specific location of the construction project. World’s first
                  to have this feature.
                  <br />
                  2. Addresses any work to POW in any location, to any IOCA.
                  <br />
                  3. Enables the user to plan the respective construction
                  activities to the identified point of work to the execution and
                  monitoring staff, and also the sub-contractor and his workers.
                  <br />
                </div>
              </div>
            </div>
        </div>
        </div>

        <div className="container">
          <div className="masatamana5">
            <div className="d-flex flex-column justify-content-between">
              <div className="d-flex flex-column">
                <div className="masterkAms_title">Construction Project Setup</div>
                <div className="widthmasterImageandContent5">
                <img src={Construction} width={210} height={200}/>
              </div>
                <div className="masterkAms_content">
                  1. Creation of IOCA (Item of Construction Activity)
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;IOCWA (Item of Construction Work
                  Activity) and IOCMA (Item of Construction Management Activity) –
                  Separate for Work and Management activities.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Unique method of IOCA creation, under
                  a type of work.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Creation of any number of IOCA, based
                  on the attribute and variables.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Enabling the creation of precise
                  IOCA.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Unique model to create BOQ for any
                  IOCA on its location
                  <br />
                  2. The copy Bill of Quantities (BOQ) feature allows the
                  utilization of data from one item of construction work activity
                  to multiple items of construction work activity, as well as from
                  one location to multiple locations within a project.
                  <br /> 3. Logical breakdown of work location for various types
                  of structure/infrastructure as per the requirement of the
                  project
                  <br />
                  4. The type of Work location tier attributes can be further
                  broken down, as per requirements of the project.
                  <br />
                  5. Categorization of Construction Work Activity into Civil,
                  Mechanical etc. is possible.
                  <br />
                  6. The BOQ for all IOCA is taken based on the final location of
                  the IOCA under each base location.
                  <br />
                  7. Micro-level planning up to the Point of Work can be done
                  automatically / Manually.
                  <br />
                  8. Auto generation of Input Requirements based on Schedules for
                  any type of construction.
                  <br />
                  9. Inbuilt quality control to monitor and control the Quality.
                  <br />
                  10. Auto generation of Bills on the approved POW by the Project
                  Manager on completion.
                  <br />
                  11. Enables periodic work allocation to execution staff and
                  contractors based on the IOCA & location
                  <br />
                  12. View of the current stage of work allocated is enabled.
                  <br />
                  13. Efficient and effective way of reporting defective work at
                  any POW by posting an image/photo to the Project Manager.
                  <br />
                  14. Multiple periodic Reports for quick project appraisal like
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Planned vs. actuals, and
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Construction work status Reports.
                </div>
              </div>
            </div>
          </div>
        </div>

        
        <div className="container">
          <div className="masatamana6">
            <div className="d-flex flex-column justify-content-start">
              
              <div className="d-flex flex-column">
                <div className="masterkAms_title2">Monitoring with Reports</div>
                <div className="widthmasterImageandContent6">
                <img src={monitor} width={210} height={200} />
              </div>
                <div className="masterkAms_content2">
                  Various MIS Reports of Construction can be generated for the
                  Elements cited below,
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Category, Selected Category.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Sub-Category, Selected Sub-Category.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Type of Work, Selected Type of Work.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Location, Selected Location.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp;Item of Construction Activity (IOCA),
                  Selected IOCA.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Point of Work (POW), Selected POW.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Total Work/Completed Work/Pending
                  Work.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Detailed Format/Consolidated Format.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="container">
          <div className="masatamana7">
            <div className="d-flex flex-column justify-content-between">
              <div className="d-flex flex-column">
                <div className="masterkAms_title">
                  Service and Contract Management
                </div>
                <div className="widthmasterImageandContent7">
                <img src={service}  width={210} height={200}/>
              </div>
                <div className="masterkAms_content">
                  1. Quick creation of RFQ (Request for Quote) / Tender with
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Predefined templates
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Abstract of BOQ
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; The estimated cost of work is based
                  on the rates available with the company.
                  <br />
                  2. The system enables quick
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Release of Work Order
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Approval of the Work
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Reconciliation of Work Order
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Appraisal of the contractor
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Raising of Bills
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; RA Bills
                  <br />
                  3. Electronic process for Floating and receiving and Bidding (to
                  confirm).
                  <br />
                  4. Auto and immediate evaluation of RFQ / Tender.
                  <br />
                  5. Auto sensitive Analysis as per the requirement.
                  <br />
                  6. Library of standard templates of LOI, Work Order, and Tender
                  that can be used for any type of construction work.
                  <br />
                </div>
              </div>

              
            </div>
          </div>
        </div>


        <div className="container">
          <div className="masatamana8">
            <div className="d-flex flex-column justify-content-start">
              
              <div className="d-flex flex-column">
                <div className="masterkAms_title2">Purchase Management</div>
                <div className="widthmasterImageandContent8">
                <img src={Purchase} width={210} height={200}/>
              </div>
                <div className="masterkAms_content2">
                  1. The system enables
                  <br />
                  i. Raising the indent for materials from
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; The final BOQs
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Planning up to the POW
                  <br />
                  ii. Request for proposal
                  <br />
                  iii. Validation of received proposals
                  <br />
                  iv. Release of Purchase Orders
                  <br />
                  v. Creation of Rate Contractors
                  <br />
                  vi. Machine hire orders
                  <br />
                  vii. Appraisal to vendors
                  <br />
                  viii. Reconciling purchase and hire orders
                  <br />
                  ix. Register of Released POs and HOs
                  <br />
                  2. Alerts and escalations to speed up the procurement process
                  till the material is delivered.
                  <br />
                  3. Efficient stock and cost management with rapid analysis of
                  stocks and deployment of them between the sites.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>



 <div className="container">
          <div className="masatamana9">
            <div className="d-flex flex-column justify-content-between">
              <div className="d-flex flex-column">
                <div className="masterkAms_title">Human Resource Management</div>
                <div className="widthmasterImageandContent9">
                <img src={human} width={210} height={200} />
              </div>
                <div className="masterkAms_content">
                  1. Efficient, accurate human resource management
                  <br />
                  2. Payroll
                  <br />
                  3. Eliminates physical employee-wise files
                  <br />
                  4. Creation of Job profiles and Roles of the employees
                  <br />
                  5. All key data of the employees like PAN, Address, Insurance,
                  etc. is managed.
                  <br />
                  6. Integrated Payroll with e-payslip being sent to Katchup of
                  the employees.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>


 <div className="container">
          <div className="masatamana10">
            <div className="d-flex flex-column justify-content-start">
              <div className="d-flex flex-column">
                <div className="masterkAms_title2">Finance Management</div>
                <div className="widthmasterImageandContent10">
                <img src={finance} width={210} height={200}/>
              </div>

                <div className="masterkAms_content2">
                  1. Finance – can be integrated into Tally, SAP, and other
                  Accounting Software.
                  <br />
                  2. Maintain Sales Stock: Sold, Available, Blocked, On Hold,
                  un-Released
                  <br />
                  3. Customer Profile: CRM, Customer interaction Management, Sold
                  Units Management.
                  <br />
                  4. Booking form, creation of payment schedule, receipts against
                  schedule.
                  <br />
                  5. Receipt creation, Interest on Delayed payments, Excess
                  Amounts.
                  <br />
                  6. Advance Interest Calculations with the ability for interest
                  adjustments.
                  <br />
                  7. Auto Demand letter generation, Payment Reminders.
                  <br />
                  8. Collection reminders and collection aging lists
                  <br />
                  9. Market planning, Budgets/Forecasting.
                  <br />
                  10. Brokerage and Agent Management
                  <br />
                  11. Email Templates: Birthday Wishes, Greetings, Payments,
                  general bulk email to customers, SMS messages, etc.
                  <br />
                  12. Prospecting/pre-sales to sales closure lifecycle of sales
                  mapping.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="container">
          <div className="masatamana11">
            <div className="d-flex flex-column justify-content-between">
              <div className="d-flex flex-column">
                <div className="masterkAms_title">Workflow management</div>
                <div className="widthmasterImageandContent11">
                <img src={Workflow} width={210} height={200}/>
              </div>
                <div className="masterkAms_content">
                  1. KAMS provides user access control and multi-level and
                  value-based approvals.
                  <br /> 2. Defective work management controls the quality of the
                  work.
                  <br />
                  3. Alerts to User on Non-Completion of work.
                  <br />
                  4. Approval History & Auditing.
                  <br />
                  5. Reminders and user-defined dashboards allow users to
                  experience smooth workflow management.
                  <br />
                  6. KAMS workflow management reduces complexity and controls the
                  project through automation.
                  <br />
                  7. Streamline the project from Project Initiation to Completion.
                  <br />
                  8. Construction of workflow based on the user’s requirements and
                  organization setup.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>


 <div className="container">
          <div className="masatamana12">
            <div className="d-flex flex-column justify-content-start">
              <div className="d-flex flex-column">
                <div className="masterkAms_title2">
                  KSMACC – Inbuilt Advanced CLOSED Communication
                </div>
                <div className="widthmasterImageandContent12">
                <img src={inbuilt} width={210} height={200}/>
              </div>
                <div className="masterkAms_content2">
                  &nbsp;&nbsp; &#8226; &nbsp; End-to-End Encryption.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Role-based Communication Channels.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Continuity of the Communication.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Company Contact’s Directory.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Data owned and managed by the
                  Company.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Configurable as per needs. <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Administered by the Admin of the
                  company.
                  <br />
                  &nbsp;&nbsp; &#8226; &nbsp; Patented features in Katchup and
                  Kall modules and much more advanced than WhatsApp / Telegram.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    


    </div>
    
  );
}

export default Kams;
