import React, { useState } from "react";
import "./Home.css";
import aboutImg from "../assests/aboutImg.png";
import firstOur from "../assests/firstOur.png";
import SecondOur from "../assests/SecondOur.png";
import ThirdOur from "../assests/ThirdOur.png";
import foueour from "../assests/foueour.png";
import fiveour from "../assests/fiveour.png";
import siOur from "../assests/siOur.png";
import sevnOutr from "../assests/sevnOutr.png";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { CModal } from "@coreui/react";
import { AiOutlineClose } from "react-icons/ai";

function Home() {
  const navigate = useNavigate("");
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <div className="homefirst">
        <div className="bklur">
          <div className="paddingblur">
            <div className="wwelcome">Welcome To Projectsoft</div>
            <div className="Bigblur">
              The Project Management
              <br />
              Solutions Provider
            </div>
            <div className="smallBlur">
              Project Soft, as a Project Management Solutions Provider offers
              you <br />
              an array of solutions in The Management of Construction, Real{" "}
              <br />
              Estate, Intellectual Property, Digital Drawings, Digital Book
              Content <br />
              Creation, Software Development and many more....
            </div>
            <div
              className="blur-button"
              onClick={() => {
                setVisible(!visible);
              }}
            >
              Request Demo
            </div>
          </div>
        </div>
      </div>

      <div className="Back_Image">
        <CModal alignment="center" visible={visible}>
          <div className="Modal">
            <div className="d-flex justify-content-end pb-3">
              <AiOutlineClose
                size={30}
                color="white"
                onClick={() => setVisible(false)}
                className="cursor-pointer"
              />
            </div>
            <div className="Back">
              <div className="Modal_Css mb-3">Request Demo</div>
              <div className="d-flex flex-column gap-3 justify-content-center align-items-center">
                <div>
                  <input type="text" placeholder="Name" className="InputCss" />
                </div>
                <div>
                  <input
                    type="number"
                    placeholder="Phone no"
                    className="InputCss"
                  />
                </div>
                <div>
                  <input type="email" placeholder="Mail" className="InputCss" />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Company Name"
                    className="InputCss"
                  />
                </div>
                <div>
                  <select name="cars" id="cars" className="InputCss">
                    <option value="" disabled selected>
                      Select Software
                    </option>
                    <option value="KAMS">
                      KAMS (Construction Activity Management Solution)
                    </option>
                    <option value="REMSS">
                      REMSS (Real Estate Marketing & Sales Solution)
                    </option>
                    <option value="IPAMS">
                      IPAMS (Intellectual Property Activity Management Solution)
                    </option>
                    <option value="DDMS">
                      DDMS (Digital Drawing Management Solution)
                    </option>
                    <option value="DBCCM">
                      DBCCM (Digital Book Content Creation Management Solution)
                    </option>
                    <option value="PAMS">
                      PAMS (Project Activities Management Solution)
                    </option>
                    <option value="SDMS">
                      SDMS (Software Development Management Solution)
                    </option>
                  </select>
                </div>
                <div>
                  <button className="ButtonCss">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </CModal>
      </div>

      {/* About start */}

      <div className="container mt-3 mb-3">
        <div className="resMenu_web">
          <div className="d-flex flex-row justify-content-start gapFix">
            <div className="d-flex flex-column">
              <div className="HaboutusWord">About Us</div>
              <div className="hAboutTextbig ">
                Project Soft Global Private Limited is promoted by <br />
                Pioneer Group of Companies.
              </div>
              <div className="smallaboutH mt-3">
                Chennai-based Pioneer Group was established in the year 1961.
                Initially, it was launched in the name of PIONEER ENGINEERING
                CORPORATION (PEC) as a Proprietary firm under the leadership of
                the Late Prof. K. Subbiah, who was a Post Graduate of Structural
                Engineering from the University of Manchester, UK.
              </div>
              <div className="smallaboutH mt-3">
                He was the acting Principal of PSG College of Technology –
                Coimbatore, before initiating PEC.
              </div>{" "}
              <div
                className="AboutBtn mt-4"
                onClick={() => {
                  navigate("/about");
                }}
              >
                {" "}
                Read More
              </div>
            </div>
            <div>
              <img src={aboutImg} />
            </div>
          </div>
        </div>

        <div className="resMenu">
          <div className="d-flex flex-column justify-content-around">
            <div className="d-flex flex-column">
              <div className="HaboutusWord">About Us</div>
              <div className="hAboutTextbig">
                Project Soft Global Private Limited is <br /> promoted by
                Pioneer Group of
                <br /> Companies.
              </div>
              <div className="smallaboutH">
                Chennai-based Pioneer Group was established in the year 1961.
                Initially, it was launched in the name of PIONEER ENGINEERING
                CORPORATION (PEC) as a Proprietary firm under the leadership of
                the Late Prof. K. Subbiah, who was a Post Graduate of Structural
                Engineering from the University of Manchester, UK.
              </div>
              <div className="smallaboutH mt-3">
                He was the acting Principal of PSG College of Technology –
                Coimbatore, before initiating PEC.
              </div>{" "}
              <div
                className="AboutBtn mt-4"
                onClick={() => {
                  navigate("/about");
                }}
              >
                {" "}
                Read More
              </div>
            </div>
            <div className="homeaboutUsmoBi">
              <img src={aboutImg} width="100%" />
            </div>
          </div>
        </div>
      </div>

      {/* About end */}

      {/* Our Products start*/}
      <div className="container mt-5">
        <div className="ourProdhead">Our Products</div>
        <div className="resMenu_web">
          <div className="d-flex flex-row justify-content-between mt-5">
            <div className="d-flex flex-column gap-1">
              <div>
                <img src={firstOur} width="100%" />
              </div>
              <div className="prodOurHeadr">KAMS</div>
              <div className="ouProdsEconR">
                Konstruction Activity Management Solution
              </div>
              <div className="ourProTextA">
                KAMS is an end-to-end digital construction activity management
                solution for any type of construction from a small residential
                building to a huge infra project.
              </div>
              <div
                className="ouRbtnReadMoe "
                onClick={() => {
                  navigate("/kams");
                }}
              >
                Read More
              </div>
            </div>
            <div className="d-flex flex-column gap-1">
              <div>
                <img src={SecondOur} width="100%" />
              </div>
              <div className="prodOurHeadr">REMSS</div>
              <div className="ouProdsEconR">
                Real Estate Marketing & Sales Solution
              </div>
              <div className="ourProTextA">
                REMSS is the complete Sales and Marketing Solution for any Real
                Estate project. REMSS can efficiently manage multiple projects
                of multiple companies of a Group of companies.
              </div>
              <div
                className="ouRbtnReadMoe "
                onClick={() => {
                  navigate("/rems");
                }}
              >
                Read More
              </div>
            </div>
            <div className="d-flex flex-column gap-1">
              <div>
                <img src={ThirdOur} width="100%" />
              </div>
              <div className="prodOurHeadr">IPAMS</div>
              <div className="ouProdsEconR">
                Intellectual Property Activity Management Solution
              </div>
              <div className="ourProTextA">
                IPAMS is the comprehensive management of Intellectual property
                filing and maintenance activities and enables you to file any
                number of IP assets in multiple countries.
              </div>
              <div className="ouRbtnReadMoe ">Read More</div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between mt-5">
            <div className="d-flex flex-column gap-1">
              <div>
                <img src={foueour} width="100%" />
              </div>
              <div className="prodOurHeadr">DDMS</div>
              <div className="ouProdsEconR">
                Digital Drawing Management Solution
              </div>
              <div className="ourProTextA">
                An integrated digital drawing management solution with internal
                secured communication facilitating multiple clients, multiple
                projects, multiple teams, and multiple simultaneous tasks.
              </div>
              <div className="ouRbtnReadMoe ">Read More</div>
            </div>
            <div className="d-flex flex-column gap-1">
              <div>
                <img src={fiveour} width="100%" />
              </div>
              <div className="prodOurHeadr">DBCCMS</div>
              <div className="ouProdsEconR">
                Digital Book Content Creation Management Solu...
              </div>
              <div className="ourProTextA">
                DBCCMs empowers digital book content creators to competently
                manage the countless tasks of Digital Book Content like text,
                audio, video, 3D, and Question Banks for any kind of digital
                book.
              </div>
              <div className="ouRbtnReadMoe ">Read More</div>
            </div>
            <div className="d-flex flex-column gap-1">
              <div>
                <img src={siOur} width="100%" />
              </div>
              <div className="prodOurHeadr">PAMS</div>
              <div className="ouProdsEconR">
                Project Activities Management Solution
              </div>
              <div className="ourProTextA">
                PAMS provides an extensive solution for managing all your
                Projects in one place covering all activities of the project
                life cycle including Initiation, Planning, Execution, and
                Closure.
              </div>
              <div className="ouRbtnReadMoe ">Read More</div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between mt-5 mb-3">
            <div className="d-flex flex-column gap-1">
              <div>
                <img src={sevnOutr} width="100%" />
              </div>
              <div className="prodOurHeadr">SDMS</div>
              <div className="ouProdsEconR">
                Software Development Management Solution
              </div>
              <div className="ourProTextA">
                Exhaustive Management solution for all your Software development
                covering all aspects of SDLC from requirement gathering, design,
                development, testing, deployment, and maintenance.
              </div>
              <div className="ouRbtnReadMoe ">Read More</div>
            </div>
            <div className="d-flex flex-column gap-1"></div>
            <div className="d-flex flex-column gap-1"></div>
          </div>
        </div>

        {/* mobi */}
        <div className="resMenu">
          <div className="d-flex flex-column justify-content-between mt-5">
            <div className="d-flex flex-column gap-1 mb-3">
              <div>
                <img src={firstOur} width="100%" />
              </div>
              <div className="prodOurHeadr">KAMS</div>
              <div className="ouProdsEconR">
                Konstruction Activity Management Solution
              </div>
              <div className="ourProTextA">
                KAMS is an end-to-end digital construction activity management
                solution for any type of construction from a small residential
                building to a huge infra project.
              </div>
              <div
                className="ouRbtnReadMoe "
                onClick={() => {
                  navigate("/kams");
                }}
              >
                Read More
              </div>
            </div>
            <div className="d-flex flex-column gap-1 mb-3">
              <div>
                <img src={SecondOur} width="100%" />
              </div>
              <div className="prodOurHeadr">REMSS</div>
              <div className="ouProdsEconR">
                Real Estate Marketing & Sales Solution
              </div>
              <div className="ourProTextA">
                REMSS is the complete Sales and Marketing Solution for any Real
                Estate project. REMSS can efficiently manage multiple projects
                of multiple companies of a Group of companies.
              </div>
              <div
                className="ouRbtnReadMoe "
                onClick={() => {
                  navigate("/rems");
                }}
              >
                Read More
              </div>
            </div>
            <div className="d-flex flex-column gap-1 mb-3">
              <div>
                <img src={ThirdOur} width="100%" />
              </div>
              <div className="prodOurHeadr">IPAMS</div>
              <div className="ouProdsEconR">
                Intellectual Property Activity Management Solution
              </div>
              <div className="ourProTextA">
                IPAMS is the comprehensive management of Intellectual property
                filing and maintenance activities and enables you to file any
                number of IP assets in multiple countries.
              </div>
              <div className="ouRbtnReadMoe ">Read More</div>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex flex-column gap-1 mb-3">
              <div>
                <img src={foueour} width="100%" />
              </div>
              <div className="prodOurHeadr">DDMS</div>
              <div className="ouProdsEconR">
                Digital Drawing Management Solution
              </div>
              <div className="ourProTextA">
                An integrated digital drawing management solution with internal
                secured communication facilitating multiple clients, multiple
                projects, multiple teams, and multiple simultaneous tasks.
              </div>
              <div className="ouRbtnReadMoe ">Read More</div>
            </div>
            <div className="d-flex flex-column gap-1 mb-3">
              <div>
                <img src={fiveour} width="100%" />
              </div>
              <div className="prodOurHeadr">DBCCMS</div>
              <div className="ouProdsEconR">
                Digital Book Content Creation Management Solu...
              </div>
              <div className="ourProTextA">
                DBCCMs empowers digital book content creators to competently
                manage the countless tasks of Digital Book Content like text,
                audio, video, 3D, and Question Banks for any kind of digital
                book.
              </div>
              <div className="ouRbtnReadMoe ">Read More</div>
            </div>
            <div className="d-flex flex-column gap-1 mb-3">
              <div>
                <img src={siOur} width="100%" />
              </div>
              <div className="prodOurHeadr">PAMS</div>
              <div className="ouProdsEconR">
                Project Activities Management Solution
              </div>
              <div className="ourProTextA">
                PAMS provides an extensive solution for managing all your
                Projects in one place covering all activities of the project
                life cycle including Initiation, Planning, Execution, and
                Closure.
              </div>
              <div className="ouRbtnReadMoe ">Read More</div>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between mb-3">
            <div className="d-flex flex-column gap-1">
              <div>
                <img src={sevnOutr} width="100%" />
              </div>
              <div className="prodOurHeadr">SDMS</div>
              <div className="ouProdsEconR">
                Software Development Management Solution
              </div>
              <div className="ourProTextA">
                Exhaustive Management solution for all your Software development
                covering all aspects of SDLC from requirement gathering, design,
                development, testing, deployment, and maintenance.
              </div>
              <div className="ouRbtnReadMoe ">Read More</div>
            </div>
            <div className="d-flex flex-column gap-1"></div>
            <div className="d-flex flex-column gap-1"></div>
          </div>
        </div>
        {/* mobi */}
      </div>
      {/* Our Products end*/}

      {/* demo Form start */}
      <div className="mt-5">
        <div className="demoBlur">
          <div className="demoBluR2">
            <div className="container">
              <div className="demoHomeCssweb">
                <div className="d-flex flex-column paddingDemofircoL">
                  <div className="demoFtext">For Demo of Our Products</div>
                  <div className="demoStext">
                    A One-stop shop to
                    <br /> manage A to Z projects
                  </div>
                  <div className="demoTtext">
                    Book your demo to enter the future world of possibilities
                    and to experience firsthand the State-of-the-art Project
                    Management Solutions crafted by Experts and guided by
                    cutting-edge technology.
                  </div>
                </div>
                <div className="paddingDemosecCoL">
                  <div className="cardSecWitheight">
                    <div className="secColdemoLabel mx-3">Get a Free Demo</div>
                    <input
                      type="text"
                      placeholder="Name"
                      className="InputDemoSec mx-3 px-3 mb-3"
                    />
                    <input
                      type="text"
                      placeholder="Phone no"
                      className="InputDemoSec mx-3 px-3 mb-3"
                    />
                    <input
                      type="text"
                      placeholder="Mail"
                      className="InputDemoSec mx-3 px-3 mb-3"
                    />
                    <input
                      type="text"
                      placeholder="Company Name & Details"
                      className="InputDemoSec mx-3 px-3 mb-3"
                    />
                    <select
                      name="cars"
                      id="cars"
                      className="InputDemoSec mx-3 px-3  mb-3"
                    >
                      <option value="" disabled selected>
                        Select Software
                      </option>
                      <option value="KAMS">
                        KAMS (Construction Activity Management Solution)
                      </option>
                      <option value="REMSS">
                        REMSS (Real Estate Marketing & Sales Solution)
                      </option>
                      <option value="IPAMS">
                        IPAMS (Intellectual Property Activity Management
                        Solution)
                      </option>
                      <option value="DDMS">
                        DDMS (Digital Drawing Management Solution)
                      </option>
                      <option value="DBCCM">
                        DBCCM (Digital Book Content Creation Management
                        Solution)
                      </option>
                      <option value="PAMS">
                        PAMS (Project Activities Management Solution)
                      </option>
                      <option value="SDMS">
                        SDMS (Software Development Management Solution)
                      </option>
                    </select>
                    <div className="demoFormbTn mx-3">Submit</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* demo Form end */}
    </div>
  );
}

export default Home;
