import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import Kams from "./components/Kams";
import "./Style.css";
import "./Responsive.css";
import Rems from "./components/Rems";

function App() {
  return (
    <div className="App">
      <div className="headerStatic">
        <Header />
      </div>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/kams" element={<Kams />}></Route>
        <Route path="/rems" element={<Rems />}></Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
