import React, { useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import "./Header.css";
import logo from "../assests/Logo.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CModal } from "@coreui/react";
import Select from "react-select";
import { AiOutlineClose } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";

function Header() {
  const [drop, setDrop] = useState(false);

  const [visible, setVisible] = useState(false);

  const [rightOpen, setRightOpen] = useState(false);
  const [rightOpenadd, setRightOpenadd] = useState(false);

  const handleMouseEnter = () => {
    setDrop(true);
  };

  const handleMouseLeave = () => {
    setDrop(false);
  };
  const navigate = useNavigate("");

  return (
    <div>
      <div className="resMenu_web">
        <div className="headrsoft">
          <div className="headerLi">
            <div className="d-flex flex-column align-items-center">
              <span>
                <img src={logo} />
              </span>
              <span className="logoheader">Projectsoft</span>
            </div>
            <Link
              to={"/"}
              className="heaerNavs"
              onClick={() => {
                setDrop(false);
              }}
            >
              {" "}
              <span className="heaerNavs ">Home</span>
            </Link>
            <Link
              to={"/about"}
              className="heaerNavs"
              onClick={() => {
                setDrop(false);
              }}
            >
              <span className="heaerNavs ">About Us</span>
            </Link>
            <div
              className="heaerNavs "
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <span>Products</span>
              <span>
                <BiChevronDown />
              </span>

              {drop && (
                <div>
                  <div class="card">
                    <div className="header">
                      <div className="dropTextHeadmain">
                        <span
                          className="dropTextHead"
                          onClick={() => {
                            navigate("/kams");
                            setDrop(false);
                          }}
                        >
                          KAMS (Consruction Activity Management system)
                        </span>
                        <span
                          className="dropTextHead"
                          onClick={() => {
                            navigate("/rems");
                            setDrop(false);
                          }}
                        >
                          REMSS (Real Estate Marketing & Sales Software )
                        </span>
                        <span className="dropTextHead">
                          IPAMS (Intellectual Property Activity Management
                          Software)
                        </span>
                        <span className="dropTextHead">
                          DDMS (Digital Drawing Management Software )
                        </span>
                        <span className="dropTextHead">
                          DBCCM (Digital Book Content Creation Management
                          Solution )
                        </span>
                        <span className="dropTextHead">
                          PAMS (Project Activities Management Solution )
                        </span>
                        <span className="dropTextHead">
                          SDMS (Software Development Management Solution )
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Link
              to={"/contact"}
              className="heaerNavs"
              onClick={() => {
                setDrop(false);
              }}
            >
              <span className="heaerNavs ">Contact Us</span>
            </Link>
          </div>

          <div
            className="d-flex gap-3 "
            onClick={() => {
              setDrop(false);
            }}
          >
            <button
              type="button"
              className="header-button "
              onClick={() => {
                setVisible(!visible);
                setDrop(false);
              }}
            >
              Request Demo
            </button>
          </div>
        </div>
      </div>

      <div className="resMenu">
        <div className="d-flex flex-row justify-content-between px-3 py-2">
          <div className="d-flex flex-column align-items-center">
            <span>
              <img src={logo} />
            </span>
            <span className="logoheader">Projectsoft</span>
          </div>
          <div className="mt-3">
            <div
              onClick={() => {
                setRightOpen(!rightOpen);
                setRightOpenadd(false);
              }}
            >
              <GiHamburgerMenu />
            </div>
          </div>
        </div>
      </div>

      <div className="Back_Image">
        <CModal alignment="center" visible={visible}>
          <div className="Modal">
            <div className="d-flex justify-content-end pb-3">
              <AiOutlineClose
                size={30}
                color="white"
                onClick={() => setVisible(false)}
                className="cursor-pointer"
              />
            </div>
            <div className="Back">
              <div className="Modal_Css mb-3">Request Demo</div>
              <div className="d-flex flex-column gap-3 justify-content-center align-items-center">
                <div>
                  <input type="text" placeholder="Name" className="InputCss" />
                </div>
                <div>
                  <input
                    type="number"
                    placeholder="Phone no"
                    className="InputCss"
                  />
                </div>
                <div>
                  <input type="email" placeholder="Mail" className="InputCss" />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Company Name"
                    className="InputCss"
                  />
                </div>
                <div>
                  <select name="cars" id="cars" className="InputCss">
                    <option value="" disabled selected>
                      Select Software
                    </option>
                    <option value="KAMS">
                      KAMS (Construction Activity Management Solution)
                    </option>
                    <option value="REMSS">
                      REMSS (Real Estate Marketing & Sales Solution)
                    </option>
                    <option value="IPAMS">
                      IPAMS (Intellectual Property Activity Management Solution)
                    </option>
                    <option value="DDMS">
                      DDMS (Digital Drawing Management Solution)
                    </option>
                    <option value="DBCCM">
                      DBCCM (Digital Book Content Creation Management Solution)
                    </option>
                    <option value="PAMS">
                      PAMS (Project Activities Management Solution)
                    </option>
                    <option value="SDMS">
                      SDMS (Software Development Management Solution)
                    </option>
                  </select>
                </div>
                <div>
                  <button className="ButtonCss">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </CModal>
      </div>

      {rightOpen && (
        <div className="containerRTL">
          <div className="pageRTL">
            <div className="sideBox">
              <span
                className="sideText"
                onClick={() => {
                  navigate("/");
                  setRightOpen(false);
                }}
              >
                Home
              </span>
              <span
                className="sideText"
                onClick={() => {
                  navigate("/about");
                  setRightOpen(false);
                }}
              >
                About Us
              </span>
              <span
                className="sideText"
                onClick={() => {
                  setRightOpenadd(!rightOpenadd);
                }}
              >
                Products
              </span>

              {rightOpenadd && (
                <>
                  <div
                    className=" sideText "
                    onClick={() => {
                      navigate("/kams");
                      setRightOpen(false);
                    }}
                  >
                    KAMS{" "}
                  </div>
                  <div
                    className="sideText"
                    onClick={() => {
                      navigate("/rems");
                      setRightOpen(false);
                    }}
                  >
                    REMSS{" "}
                  </div>
                  <div className=" sideText">IPAMS </div>
                  <div className=" sideText">DDMS </div>
                  <div className=" sideText">DBCCM </div>
                  <div className=" sideText">PAMS </div>
                  <div className=" sideText">SDMS </div>
                </>
              )}
              <span
                className="sideText"
                onClick={() => {
                  navigate("/contact");
                  setRightOpen(false);
                }}
              >
                Contact Us
              </span>
              <span
                className="sideText"
                onClick={() => {
                  setVisible(true);
                  setRightOpen(false);
                }}
              >
                Request Demo
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
