import React from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router";

function Rems() {
  const navigate = useNavigate("");
  return (
    <div>
      <div className="Mtop">
        <div className="container">
          <div className="d-flex flex-column kamSPADD gap-4">
            <span className="kamsTitlE">REMSS</span>
            <span className="kamsTitlE2">
              Real Estate Marketing & Sales Solution
            </span>
            <span className="kamsTitlE3">
              End-to-end solution for Marketing and Sales to your Real Estate
              projects.
            </span>
          </div>
        </div>
      </div>
      <div className="Back_CS">
        <span className="CS_Font">Coming Soon</span>
        <div>
          <span
            className="Back_Font"
            onClick={() => {
              navigate("/");
            }}
          >
            {" "}
            <IoIosArrowRoundBack size={33} /> &nbsp; Back
          </span>
        </div>
      </div>
    </div>
  );
}

export default Rems;
