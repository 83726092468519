import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { BsTelephoneFill } from "react-icons/bs";

function Contact() {
  return (
    <div>
      <div className="aboutbanner">
        <div className="aboutBannerBlur">
          <div className="abouTilr">Contact Us</div>
        </div>
      </div>

      <div className="container">
        <div className="contactMapbetween mt-5 mb-5">
          <div className="d-flex flex-column gap-3">
            <div className="contactGetTit">Get in touch</div>
            <div className="d-flex flex-row gap-2">
              <span>
                <FaMapMarkerAlt />{" "}
              </span>
              <span className="FirstcOlsoftsuB">
                1st Floor, "CHETTINAD CHAMBERS", No 39, 5th Street <br />
                Radha Krishnan Salai, Mylapore, Chennai- 600004,
                <br />
                Tamil Nadu, India.
              </span>
            </div>
            <div className="d-flex flex-row gap-2 align-items-center">
              <span>
                <GrMail />{" "}
              </span>
              <span className="FirstcOlsoftsuBa">support@projectsoft.com</span>
            </div>
            <div className="d-flex flex-row gap-2 align-items-center">
              <span>
                <BsTelephoneFill />{" "}
              </span>
              <span className="FirstcOlsoftsuBa">+91 98848 00819</span>
            </div>
          </div>

        

      <div className="container d-flex justify-content-end">
        <div class="mapouter">
          <div class="gmap_canvas">
            <iframe
              width="100%"
              height="383px"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=chettinad Chambers&t=&z=20&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
        </div>
      </div>

      </div>
      </div>

    </div>
  );
}

export default Contact;
